import React             from "react";
import {Pages}           from "./Pages";
import ReactDOM          from "react-dom";
import {UserProfile}     from "../components/UserProfile";
import {UserSection}     from "../components/UserSection";
import {ElementsContext} from "../enums/ElementsContext";

export class UsersProfile extends Pages {

    constructor(params) {
        super(params);
    }

    initPageHandlers(params) {
        super.initPageHandlers(params);

        this.data = window.diaryJsData;

        ReactDOM.render(
            <UserProfile
                user={this.data.element}/>,
            document.getElementById('userForm')
        );

    }

}
