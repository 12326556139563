import React, {Fragment, useEffect, useState} from "react";
import PropTypes, {element} from "prop-types";
import {ErrorContainer}     from "../dom/ErrorContainer";
import {PersonForm}         from "./PersonForm";
import {ElementsContext}    from "../enums/ElementsContext";
import {UserSection}        from "./UserSection";

const axios = require("axios").default;

export const UserProfile = props => {
    const [user, setUser] = useState(props.user || {});
    const [roles, setRoles] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        let cleanupFunction = false;

        function rolesCallback(roles) {
            if (!cleanupFunction && roles) {
                setRoles(roles);
            }
        }

        getRoles(rolesCallback);
        return () => {
            cleanupFunction = true;
        };
    }, []);

    useEffect(() => {
        if (!user.role?.length && user.role_id) {
            user.role = roles.filter((role, index) => {
                return +role.ID === +user.role_id;
            }).shift();
        }
    });

    const onPersonChange = (personId) => {
        setUser({...user, person_id: personId});
    };

    const onUserChange = (user_id = "") => {
        props.onUserChange?.(user);
    };

    const getRoles = (callback) => {
        axios.get('/api/roles/')
             .then((response) => {
                 callback(response.data);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, error.response.data]);
                 callback(false);
             });
    };

    const RenderContent = compProps => {
        return <UserSection
            user={user || {}}
            edit={true}
        />
    };

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}
            {user.person.ID ?
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h5 className={"card-title"}>
                                    Персональная информация
                                </h5>
                                <PersonForm
                                    context={ElementsContext.USER}
                                    person={user.person}
                                    onPersonChange={onPersonChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    Для этого пользователя не установлены персональные данные.
                    <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close"></button>
                </div>
            }
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h5 className={"card-title"}>
                                Данные пользователя
                            </h5>
                            <RenderContent/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

};

UserProfile.propTypes = {
    user: PropTypes.object,
};


